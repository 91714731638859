// i18next-extract-mark-ns-start accessibility

import { graphql } from 'gatsby';
import React from 'react';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';

const AccessibilityPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	return (
		<Page>
			<SEO
				title={t('seo_title')}
				description={t('seo_description')}
			/>

			<Banner
				title={t('title')}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<h2>We want everyone who visits the Realis website to feel welcome and find the experience rewarding</h2>
						<p>
							This accessibility statement is part of a formal policy (in line with the Equality Act 2010)
							that recognises the importance of equality and diversity, supporting equal opportunities for all.
						</p>
						<p>
							We want everyone who visits the Realis website to feel welcome and find the experience rewarding.
						</p>
						<p>
							At Realis we value diversity; recognising that a more diversity creates a richer and more varied working environment.
							Diversity also drives innovation, by enabling us to offer our clients the best consultancy service that we can.
						</p>
						<h2>Changing settings</h2>
						<p>
							Using your web browser, you can change how you view our website, for example adjusting the size of text or colours.
							For more information, we recommend that you read the following:
						</p>
						<ul>
							<li>

								<a href="https://www.w3.org/WAI/users/browsing">Better Web Browsing: Tips for Customising Your Computer from The World Wide Web Consortium (W3C)</a>
							</li>
							<li>
								<a href="https://www.bbc.co.uk/accessibility">My Web My Way</a>{' '} from the BBC
							</li>
						</ul>
						<h2>What are we doing?</h2>
						<p>
							To help us make the Realis website a positive place for everyone, we've been using the
							{' '}<a href="https://www.w3.org/TR/WCAG/">Web Content Accessibility Guidelines (WCAG) 2.0"</a>.{' '}
							These guidelines explain how to make web content more accessible for people with disabilities, and user friendly for everyone.
							The guidelines have three levels of accessibility (A, AA and AAA). We’ve chosen Level A as the target for the Realis website.
						</p>
						<h2>How are we doing?</h2>
						<p>
							We've worked hard on the Realis website and believe we've achieved our goal of Level A accessibility.
							We monitor the website regularly to maintain this, but if you do find any problems, please get in touch.
						</p>
						<p>
							The WAI have produced a comprehensive guide for contacting organisations about accessibility challenges –
							click {' '}<a href="https://www.w3.org/WAI/users/inaccessible">here</a>{' '} to find out more.
						</p>
						<p>
							If you enjoyed using the Realis website, or if you had trouble with any part of it, please get in touch.
							We'd like to hear from you - {' '}<a href="https://www.realis-simulation.com/about-us/contact-us/">contact us</a>.
						</p>
					</Trans>
				</div>
			</PageSection>
		</Page>
	);
};

export default AccessibilityPage;

export const pageQuery = graphql`
	query ($language: String!) {
		locales: allLocale(
			filter: { ns: { in: ["accessibility", "_common"] }, language: { eq: $language } }
		) {
			edges {
				node {
				ns
				data
					language
				}
			}
		}
		banner: file(relativePath: { eq: "content/banner-connected.png" }) {
			...imageCarousel
		}
	}
`;
